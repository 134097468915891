import logo from './img/logo.png';
import us from './img/analytics-7168479_640.png';
import dataP from './img/dart-155726_640.png';
import creative from './img/artist-1297400_640.png';
import trace from './img/graph-6030184_640.png';
import optimize from './img/tools-98391_640.png';
import clientp from './img/headset-7727268_640.png';
import cross from './img/social-media-6073859_640.png';
import inovation from './img/light-311311_640.png';
import flexible from './img/digital-nomad-6956070_640.png';
import fb from './img/communication_15047435.png';
import x from './img/twitter-alt-circle_12107562.png';
import ig from './img/instagram_2111463.png';
import './App.css';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';

function App() {
  const form = useRef();

  const send = async (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_u0lucxo', 'template_q7uxy1l', form.current, {
        publicKey: 'eiBuna01VSFwEN924',
      })
      .then(
        () => {
          alert("We have received your submission! We will reply to you within 1-2 business days.");
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div className="App">
      <nav>
        <a className='main' href='#home'>
          <div>
            <img src={logo} alt='Logo'></img>
            <h2>ImpulseAd</h2>
          </div>
        </a>
        <ul>
          <li><a href='#Aboutus'>About Us</a></li>
          <li><a href='#ourfeatures'>Our Features</a></li>
          <li><a href='#contactus'>Contact Us</a></li>
        </ul>
      </nav>
      <section id='home'>
        <div>
          <h1>Welcome</h1>
          <h3>Engage. Influence. Succeed – Start Your Campaign Today!</h3>
        </div>
      </section>
      <section id='Aboutus'>
        <h2>About Us</h2>
        <div>
          <p>Welcome to ImpulseAd – where we turn clicks into connections, and campaigns into conversions. Founded in 2024, our mission is to elevate your brand's online presence, engaging your audience with compelling and effective advertising that captures attention and drives results.
            <br></br><br></br>
            At ImpulseAd, we’re more than just digital marketers. We’re a team of passionate creators, strategists, and innovators dedicated to helping brands thrive in an ever-evolving digital world. From startups finding their voice to established businesses seeking fresh engagement, we tailor our approach to each client’s unique goals and audience, delivering campaigns that stand out.
            <br></br><br></br>
            Our expertise spans the most powerful advertising platforms, including Google Ads and Facebook Ads, ensuring that your message reaches the right audience at the right time. We combine data-driven insights with creative vision to craft ads that resonate, optimize continuously to maximize performance, and provide transparent reporting so you can see the impact of every campaign.
            <br></br><br></br>
            ImpulseAd was founded on the belief that digital advertising should be accessible, effective, and above all, authentic. With our commitment to innovation and growth, we’re excited to be your partner on this journey. Let’s create something extraordinary together.</p>
          <img src={us} alt='us'></img>
        </div>
      </section>
      <section id='ourfeatures'>
        <h2>Our Features</h2>
        <div className='maina'>
          <div className='feature'>
            <h3>Data-Driven Precision</h3>
            <div>
              <img src={dataP} alt='Data-Driven Precision'></img>
              <p>Our campaigns are rooted in real-time analytics, ensuring every ad is optimized for reach and impact.</p>
            </div>
          </div>

          <div className='feature'>
            <h3>Creative Strategy Development</h3>
            <div>
              <img src={creative} alt='Creative Strategy Development'></img>
              <p>We craft ad strategies tailored to your brand’s personality and goals, delivering campaigns that resonate with your audience.</p>
            </div></div>

          <div className='feature'>
            <h3>Transparent Performance Tracking</h3>
            <div>
              <img src={trace} alt='Transparent Performance Tracking'></img>
              <p>Complete transparency with clear reporting lets you track every campaign’s performance in real-time.</p>
            </div></div>

          <div className='feature'>
            <h3>Adaptability & Rapid Optimization</h3>
            <div>
              <img src={optimize} alt='Adaptability & Rapid Optimization'></img>
              <p>We monitor ad performance continuously, adapting campaigns in real-time to ensure maximum relevance and impact.</p>
            </div></div>

          <div className='feature'>
            <h3>Personalized Client Support</h3>
            <div>
              <img src={clientp} alt='Personalized Client Support'></img>
              <p>Our team is dedicated to personalized, responsive support. We prioritize your insights and work closely with you to refine your campaigns.</p>
            </div></div>

          <div className='feature'>
            <h3>Cross-Platform Expertise</h3>
            <div>
              <img src={cross} alt='Cross-Platform Expertise'></img>
              <p>We specialize in campaigns across Google Ads, Facebook Ads, and more, creating a cohesive, cross-channel advertising experience.</p>
            </div></div>

          <div className='feature'>
            <h3>Innovation-Focused Approach</h3>
            <div>
              <img src={inovation} alt='Innovation-Focused Approach'></img>
              <p>We bring the latest tools and strategies to every campaign, constantly exploring new ways to elevate your brand’s presence.</p>
            </div></div>

          <div className='feature'>
            <h3>Flexible, Scalable Solutions</h3>
            <div>
              <img src={flexible} alt='Flexible, Scalable Solutions'></img>
              <p>From startups to established businesses, our solutions adapt to fit your needs, providing maximum value at any stage of growth.</p>
            </div></div>

        </div>
      </section>
      <section id='contactus'>
        <h2>Contacts Us</h2>
        <div className='mainaa'>
          <form ref={form} onSubmit={send}>
            <label>Your Name</label>
            <input placeholder='Name' type='text' name='name' required></input>
            <label>Your Email</label>
            <input placeholder='Email' type='email' name='email' required></input>
            <label>How Would You like Our Help For</label>
            <textarea placeholder='Message' type='text' name='message' rows={10} required></textarea>
            <button>Send</button>
          </form>
          <div className='social'>
            <a href='https://www.impulsead.com/'><img src={fb} alt='fb'></img>Facebook</a>
            <a href='https://www.impulsead.com/'><img src={x} alt='x'></img>X</a>
            <a href='https://www.impulsead.com/'><img src={ig} alt='ig'></img>Instergram</a>
          </div>
        </div>
      </section>
      <footer>
        <ul>
          <li><a href='#Aboutus'>About Us</a></li>
          <li><a href='#ourfeatures'>Our Features</a></li>
          <li><a href='#contactus'>Contact Us</a></li>
        </ul>
      </footer>
    </div>
  );
}

export default App;
